#timelineContainer {
    color: black;
    width: 100%;
    background-color: rgb(238, 237, 237);
    padding-bottom: 5%;
}

#historyTitle {
    padding-top: 5%;
    padding-bottom: 5%;
}