#profilePic {
  width: 300px;
  height: 300px;
}

.profilePicGroup {
  position: relative;
  top: 8%;
}

#presentationContainer {
  animation: topToBottom ease 2s;
  -webkit-animation: topToBottom ease 2s;
  -moz-animation: topToBottom ease 2s;
  -o-animation: topToBottom ease 2s;
  -ms-animation: topToBottom ease 2s;
}

.newsletter-container {
  position: relative;
  border-radius: 20px;
  margin-top: 10px;
  @media (min-width: 768px) {
    display: inline-block;
    box-shadow: 0 0 10px rgb(140, 255, 180);
  }
}

#iframe {
  @media (max-width: 768px) {
    display: none;
  }
}

.my-newsletter-badge-container {
  @media (max-width: 768px) {
    right: 134px;
  }
  @media (min-width: 768px) {
    bottom: -20px;
    right: 32%;
    z-index: 10;
  }
  position: absolute;
  display: flex;
  animation: bounce 1.5s infinite;
}

.click-me-container {
  margin-top: -5px;
  margin-right: 20px;
  @media (min-width: 768px) {
    display: none;
  }
}

#click-me {
  font-style: italic;
  font-size: 14px;
}

#arrow {
  margin-top: -40px;
}

.my-newsletter-badge {
  cursor: pointer;
  height: 30px;
  font-size: small;
  box-shadow: 0 0 10px rgb(140, 255, 180);
  background-color: rgb(140, 255, 180);
  font-weight: 900;
  font-style: italic;
  color: rgb(8, 55, 23);
  padding: 5px 10px;
  border-radius: 20px;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.hide-on-landscape {
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    display: none;
  }
}

#hello {
  font: 20px "opensans-bold", sans-serif;
  letter-spacing: 2.5px;

  -webkit-animation-delay: 5s;
  -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */

  -moz-animation-delay: 5s;
  -moz-animation: fadein 5s; /* Firefox < 16 */

  -ms-animation-delay: 5s;
  -ms-animation: fadein 5s; /* Internet Explorer */

  -o-animation-delay: 5s;
  -o-animation: fadein 5s; /* Opera < 12.1 */

  animation-delay: 5s;
  animation: fadein 5s;
}

.socialIcon {
  margin: 20px;
  opacity: 0.6;
  /* animation: quickBottomUp ease 2s;
  -webkit-animation: quickBottomUp ease 2s;
  -moz-animation: quickBottomUp ease 2s;
  -o-animation: quickBottomUp ease 2s;
  -ms-animation: quickBottomUp ease 2s; */
}

.socialIcon:hover {
  opacity: 1;
}

.socialLink:hover {
  color: white;
}

/*
* FADE IN
*/

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*
* TOPTOBOTTOM
*/

@keyframes topToBottom {
  0% {
    margin-top: -15%;
  }
  100% {
    margin-top: 0%;
  }
}

@-moz-keyframes topToBottom {
  0% {
    margin-top: -15%;
  }
  100% {
    margin-top: 0%;
  }
}

@-webkit-keyframes topToBottom {
  0% {
    margin-top: -15%;
  }
  100% {
    margin-top: 0%;
  }
}

@-o-keyframes topToBottom {
  0% {
    margin-top: -15%;
  }
  100% {
    margin-top: 0%;
  }
}

@-ms-keyframes topToBottom {
  0% {
    margin-top: -15%;
  }
  100% {
    margin-top: 0%;
  }
}

/* QUICK BOTTOM UP */
@keyframes quickBottomUp {
  0% {
    margin-bottom: -15%;
  }
  100% {
    margin-bottom: 0%;
  }
}

@-moz-keyframes quickBottomUp {
  0% {
    margin-bottom: -15%;
  }
  100% {
    margin-bottom: 0%;
  }
}

@-webkit-keyframes quickBottomUp {
  0% {
    margin-bottom: -15%;
  }
  100% {
    margin-bottom: 0%;
  }
}

@-o-keyframes quickBottomUp {
  0% {
    margin-bottom: -15%;
  }
  100% {
    margin-bottom: 0%;
  }
}

@-ms-keyframes quickBottomUp {
  0% {
    margin-bottom: -15%;
  }
  100% {
    margin-bottom: 0%;
  }
}
