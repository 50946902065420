/* DivSlideshow */

#projectSlideshowSlider {
  height: 90%;
}

.slideshow {
    width: 100%;
    overflow: hidden;
    /*max-width: 500px;*/
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slide {
    display: inline-block;
    height: 350px;
    width: 100%;
    border-radius: 40px;
  }

  .projectSlide {
      display: inline-block;
      vertical-align: top;
      height: 285px;
      width: 100%;
      border-radius: 40px;
  }
  
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 3%;
    opacity: 0.6;
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    opacity: 1;
    background-color: #ffffff;
  }