h1 {
  margin-block-start: 0;
}

#biography {
  text-align: left;
}

#biographyContainer {
  /* position: absolute; */
  background-color: #667085;
  color: white;
  width: 100%;
}

#biographyText {
  margin-top: 10%;
}

#signature {
  margin-top: -10%;
  margin-left: 70%;
  float: right;
}

.hide-on-landscape {
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    display: none;
  }
}

@media (orientation: portrait) {
  .hide-on-portrait {
    display: none;
  }
}
