a {
    color: white;
}

#baby {
    width: 100%;
}

.icon:hover {
    box-shadow: inset 0 0 100px 100px rgba(13, 74, 240, 0.1);
    background-color: rgb(197, 220, 241);
    color:rgb(52, 81, 214);
}

.iconChildhood:hover {
    box-shadow: inset 0 0 100px 100px rgba(106, 7, 116, 0.1);
    background-color: rgb(245, 224, 212);
    color:rgb(236, 95, 13);
}

.iconCompulsorySchool:hover {
    box-shadow: inset 0 0 100px 100px rgba(106, 7, 116, 0.1);
    background-color: rgb(238, 208, 208);
    color:rgb(226, 7, 7);
}

.iconErasmus:hover {
    box-shadow: inset 0 0 100px 100px rgba(106, 7, 116, 0.1);
    background-color: rgb(124, 233, 103);
    color:rgb(26, 116, 8);
}

.iconQuantycaJob:hover {
    box-shadow: inset 0 0 100px 100px rgba(106, 7, 116, 0.1);
    background-color: rgb(241, 197, 239);
    color:rgb(145, 5, 121);
}

.iconTheForkJob:hover {
    box-shadow: inset 0 0 100px 100px rgba(106, 7, 116, 0.1);
    background-color: rgb(124, 233, 103);
    color:rgb(26, 116, 8);
}

.childDescription {
    color: rgb(236, 95, 13);
}

.primarySchoolDescription {
    font-weight: bold;
    color:rgb(226, 7, 7);
}

.middleSchoolDescription {
    color:rgb(226, 7, 7);
}

.highSchoolDescription {
    color:rgb(226, 7, 7);
}

.bachelorDescription {
    color:rgb(52, 81, 214);
}

.erasmusDescription {
    color:rgb(26, 116, 8);
}

.masterDescription {
    color:rgb(52, 81, 214);
}

.quantycaJobDescription {
    color:rgb(170, 8, 143);
}

.theForkJobDescription {
    color:rgb(26, 116, 8);
}


