.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.languageFlag {
  opacity: 0.6;
}

.languageFlag:hover {
  opacity: 1;
  cursor: pointer;
}

#presentation {
  min-height: 100vh;
}

#biography {
  min-height: 40vh;
}

#skills {
  min-height: 60vh;
}

.App-header {
  background-color: #282c34;
  display: flex;
  height: fit-content;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#navbar {
  position: absolute;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

#nav-wrap ul, #nav-wrap li, #nav-wrap a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

/* nav-wrap */
#nav-wrap {
  font: 12px 'opensans-bold', sans-serif;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  background-color: #282c34;
  position: fixed;
  left: 0;
  top: 0;
}
.opaque { background-color: #333; }

/* hide toggle button */
#nav-wrap > a.mobile-btn { display: none; }

ul#nav {
  min-height: 40px;
  width: auto;
  justify-content: center;

  /* center align the menu */
  text-align: center;
}
ul#nav li {
  position: relative;
  list-style: none;
  height: 40px;
  display: inline-block;
}

/* Links */
ul#nav li a {

/* 8px padding top + 8px padding bottom + 32px line-height = 48px */

  display: inline-block;
  padding: 3px 13px;
  line-height: 32px;
 text-decoration: none;
  text-align: left;
  color: #fff;

 -webkit-transition: color .2s ease-in-out;
 -moz-transition: color .2s ease-in-out;
 -o-transition: color .2s ease-in-out;
 -ms-transition: color .2s ease-in-out;
 transition: color .2s ease-in-out;
}

ul#nav li a:active { background-color: transparent !important; }
ul#nav li.current a { color: #247cf0; }
ul#nav li a {opacity: 0.8}
ul#nav li a:hover {opacity: 1}

/* mobile wide/smaller tablets
---------------------------------------------------------------------- */

@media only screen and (max-width: 767px) {

  /* mobile navigation
  -------------------------------------------------------------------- */
  .languageFlag {
    opacity: 1;
  }

  #languages {
    width: 100px;
    height: 40px;
    background-color: #1f2024;
    text-shadow: none;
    color: transparent;
    bottom: 0;
    border-radius: 4px;
  }

  #mailContainer {
    width: 50px;
    height: 40px;
    background-color: #1f2024;
    text-shadow: none;
    position: relative;
    bottom: 0;
    border-radius: 4px;
  }
  
  #nav-wrap {
     font: 12px 'opensans-bold', sans-serif;
     background: transparent !important;
     letter-spacing: 1.5px;  
     width: auto;
     position: fixed;
     top: 0;
     right: 0;
  }
  #nav-wrap > a {
    width: 48px;
   height: 48px;
   text-align: left;
   background-color: #1f2024;
   position: relative;
   border: none;
   float: right;
   font: 0/0 a;
   text-shadow: none;
   color: transparent;
   position: relative;
   top: 0px;
   right: 30px;
  }

 #nav-wrap > a:before,
  #nav-wrap > a:after {
   position: absolute;
   border: 2px solid #fff;
   top: 35%;
   left: 25%;
   right: 25%;
   content: '';
 }
  #nav-wrap > a:after { top: 60%; }

  /* toggle buttons */
 #nav-wrap:not( :target ) > a:first-of-type,
 #nav-wrap:target > a:last-of-type  {
    display: block;
    border-radius: 4px;
 }

  /* hide menu panel */
  #nav-wrap ul#nav {
     height: auto;
   display: none;
     clear: both;
     width: auto; 
     float: right;     

     position: relative;
     top: 12px;
     right: 0;
  }

  /* display menu panels */
 #nav-wrap:target > ul#nav	{
    display: block;
     padding: 30px 20px 48px 20px;
     background: #1f2024;
     margin: 0 30px;
     clear: both;
     border-radius: 4px;
  }

  ul#nav li {
     display: block;
     height: auto;      
     margin: 0 auto; 
     padding: 0 4%;           
     text-align: left;
     border-bottom: 1px solid #2D2E34;
     border-bottom-style: dotted; 
  }
 
  ul#nav li a {  
     display: block;  
     padding: 0;      
     margin: 12px 0; 
     line-height: 16px; /* reset line-height from 48px */
     border: none;
  }  

}

#languages { 
  position: fixed; 
  margin-left: 20px; 
  z-index: 999 
}

#mailContainer {
   position: fixed; 
   right: 0px; 
   margin-right: 20px; 
   z-index: 999;
}

#mailContainer {
  opacity: 0.6;
}

#mailContainer:hover {
  opacity: 1;
  cursor: pointer;
}

#websiteVersion {
  margin-bottom: 0;
}

#websiteVersion:hover {
  cursor: pointer;
}
