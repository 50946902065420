#skillsContainer {
    background-color: #1f1f1f;
    color: white;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
}

#skillsTitle {
    padding-top: 2%;
}

#skillsContent {
    height: fit-content;
}

.leftSkills {
    padding: 10%;
}

.rightSkills {
    padding: 9%;
}

.skill {
    height: 40px;
}
