.projectContainer {
    width: 100%;
    margin: 0 auto;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5%;
    padding-bottom: 5%;
    cursor: pointer;
    white-space: pre-line;
    text-align: left;
}

#closeIcon {
    float: right;
    opacity: 0.7;
    cursor: pointer;
}

#closeIcon:hover {
    opacity: 1;
}

#projectModal {
    overflow: scroll;
}