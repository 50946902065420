#projectsContainer {
    width: 100%;
    background-color: #282c34;
    color: white;
    min-height: 100vh;
    display: flex;
    justify-content: left;
    flex-direction: column;
    padding-top: 5%;
    padding-bottom: 5%;
}

#projectsTitle {
    padding-top: 2%;
}
